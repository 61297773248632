@import "../../Constants/colors";

.innInfoText {
	position: relative;
	display: block;
	margin: 18px 24px;
	letter-spacing: 0;
	font-size: 14px;
	line-height: 22px;
	color: $tomeBlack;

	@media only screen and (max-width: 1000px) {
		font-size: 16px;
	}
}

.button {
	width: calc(100vw - 48px);
	margin: 0 24px;
}

.formBlockInn {
	padding-bottom: 18px;

	@media only screen and (max-width: 1000px) {
		padding-bottom: 0;
	}
}
