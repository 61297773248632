@import "../../Constants/colors";

.wrap {
	position: relative;
	display: block;
}

.wrapInvalid {
	.label,
	.input {
		color: $tomeRed;
	}
	.content {
		border-color: $tomeRed;
	}
}

.wrapDisable {
	background-color: #fbfbfb;

	.label {
		color: rgba($tomeBlack, 0.56);
	}
}

.wrapFirst {
	.label {
		padding-top: 16px;
	}
}

.wrapLast {
	.content {
		padding-bottom: 6px;
		border-bottom: none;
	}
}

.wrapNoBorder {
	.content {
		border-bottom: none;
	}
}

.label {
	position: relative;
	display: block;
	padding-top: 8px;
	padding-left: 24px;
	font-size: 12px;
	line-height: 15px;
	color: $fontBlack;
	pointer-events: none;
}

.labelFocus {
	font-weight: 500;
	color: #c16c2f;
}

.content {
	position: relative;
	display: block;
	width: calc(100% - 24px);
	padding-right: 24px;
	margin-left: 24px;
	border-bottom: 1px solid rgba($tomeBlack, 0.1);
}

.input {
	appearance: none;
	width: 100%;
	height: 44px;
	padding: 0;
	border: none;
	font-weight: 400;
	font-size: 17px;
	color: #000;
	resize: none;

	&:focus {
		outline: none;
	}
}

.textArea {
	height: 68px;
	margin-top: 10px;
}

.suffixWrap {
	position: absolute;
	top: 50%;
	right: 10px;
	z-index: 2;
	display: flex;
	align-items: center;
	color: rgba(0, 0, 0, 0.65);
	line-height: 0;
	transform: translateY(-50%);
}

.auto {
	position: relative;
	display: block;
	right: 20px;
	height: 22px;
	opacity: 0.5;
}
