@import "src/Constants/colors";

.wrap {
  position: relative;
  display: block;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 89px;
  background-color: #f2f2f1;

  @media only screen and (max-width: 1000px) {
    padding-top: 0;
    min-height: auto;
    overflow: auto;
  }
}

.backgroundCircle {
  position: absolute;
  top: -50vw;
  left: -50vw;
  width: 100vw;
  height: 100vw;
  border-radius: 50%;
  background: radial-gradient(#f6f6f5, #f2f2f1);
  z-index: 0;
  pointer-events: none;
}

.header {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 64px;
  box-shadow: 0 0 5px -2px rgba($tomeBlack, 0.25), 0 2px 4px -4px rgba($tomeBlack, 0.15);
  background-color: $white;
  z-index: 10;

  @media only screen and (max-width: 1000px) {
    overflow: hidden;
    position: sticky;
    top: 0;
    left: 0;
    height: 54px;
    box-shadow: none;
    background-color: #dffbf5;
  }
}

.logoLink {
  position: absolute;
  left: 0;
  top: 0;
  width: 68px;
  height: 64px;

  @media only screen and (max-width: 1000px) {
    width: 65px;
    height: 54px;
  }
}

.logo {
  position: absolute;
  top: -4px;
  right: 0;
  width: 46px;
  height: 46px;

  @media only screen and (max-width: 1000px) {
    top: -1px;
    width: auto;
    height: 42px;
  }
}

.headerContent {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 1000px) {
    align-items: center;
    justify-content: flex-end;
  }
}

.headerLeft {
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
}

.headerTitleWrap {
  position: relative;
  display: flex;
  align-items: center;
  height: 64px;
  margin-right: 40px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.headerTitleWrapChoose {
  cursor: default;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    border-radius: 1px;
    background-color: #F8CA00;
    background-image: linear-gradient(-45deg, #fcd531, #4ce9f5);
    transition: opacity 100ms;
  }
}

.headerTitle {
  position: relative;
  display: inline-block;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 65px;
  color: $fontBlack;
}

.close {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 58px;
  width: 58px;
  height: 64px;
  margin-left: -40px;
  border: none;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    .closeIcon {
      display: none;
    }
    .closeRedIcon {
      display: block;
    }
  }
}

.closeIcon {
  display: block;
}
.closeRedIcon {
  display: none;
}

.mimiImage {
  position: relative;
  bottom: -2px;
  height: 42px;
  margin-right: 115px;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
}

.signIn {
  position: absolute;
  bottom: 0;
  right: 10px;
  transform: translateX(100%);
  height: 64px;
  border: none;
  font-size: 15px;
  line-height: 65px;
  color: $fontBlack;
  background: none;
  padding: 0 22px;
  cursor: pointer;

  &:focus { outline: none }
  &:hover {
    .signInImage {
      transform: translateX(3px);
    }
  }

  @media only screen and (max-width: 1000px) {
    position: relative;
    right: 142px;
    height: 54px;
    line-height: 54px;
    font-size: 16px;
  }
}

.closeMobile {
  right: 62px;
}

.signInLine {
  position: absolute;
  left: 0;
  bottom: 0;
}

.signInImage {
  width: 13px;
  margin-left: 8px;
  transition: transform 100ms;

  @media only screen and (max-width: 1000px) {
    width: 16px;
  }
}

.contentWrap {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 48px;

  @media only screen and (max-width: 1000px) {
    height: 100%;
    padding-bottom: 0;
  }
}
