@import "../../Constants/colors";

.wrap {
  position: relative;
  display: block;
  border-radius: 4px;
  background: #fff;

  @media only screen and (max-width: 1000px) {
    border-radius: 0;
  }
}

.row {
  position: relative;
  display: block;
  padding: 27px 48px 11px;
  border-bottom: 1px solid rgba($tomeBlack, 0.1);

  &:first-child {
    padding-top: 36px;
  }
  &:last-child {
    border-bottom: none;
  }

  &.rowFooter {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 1000px) {
    padding: 32px 24px 8px 0;
    margin-left: 24px;

    &:first-child {
      padding-top: 32px;
    }
  }
}

.rowFooter {
  border-bottom: none;

  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 38px;
    width: 28px;
    height: 12px;
    background-image: url('./img/corner_bottom_white.svg');
    z-index: 1;
  }

  @media only screen and (max-width: 1000px) {
    &:after {
      display: none;
    }
  }
}

.rowSmall {
  padding-top: 28px;
  padding-bottom: 14px;

  &:first-child {
    padding-top: 40px;
  }

  .content {
    margin-bottom: 14px;

    @media only screen and (max-width: 1000px) {
      margin-bottom: 24px;
    }
  }

  .left {
    width: 217px;
  }

  @media only screen and (max-width: 1000px) {
    padding-top: 32px;
    padding-bottom: 8px;

    &:first-child {
      padding-top: 32px;
    }
  }
}

.rowForm {
  padding: 0;
  border: none;
  margin: 0;

  .content {
    margin: 0;
  }
}

.title {
  position: relative;
  display: block;
  margin: 0 0 19px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $fontBlack;

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 22px;
  margin-bottom: 16px;

  @media only screen and (max-width: 1000px) {
    display: block;
    margin-bottom: 26px;
  }
}

.label {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  color: $fontBlack;
  transition: color 0.1s linear;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
    line-height: 48px;
  }
}

.labelLink {
  cursor: pointer;
  transition: color 0.1s linear;
  padding: 0;
  border: none;
  background: none;

  &:hover {
    color: $tomeRed;
  }
  &:focus {
    outline: none;
  }
}

.labelLinkGray {
  cursor: pointer;
  color: rgba($tomeBlack, 0.56);
  transition: color 0.1s linear;

  &:hover {
    color: $tomeRed;
  }
}

.checkWrap {
  position: relative;
  display: flex;
  padding: 0;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;

  &:focus { outline: none }
  &:hover {
    .label {
      color: $tomeRed;
    }
    .labelLinkGray {
      color: rgba($tomeBlack, 0.56);
    }
  }

  @media only screen and (max-width: 1000px) {
    display: block;
  }
}

.checkWrapLoading {
  pointer-events: none;
}

.left {
  position: relative;
  display: block;
  width: 272px;
}

.right {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 22px;
}
