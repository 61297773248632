@import "../../Constants/colors";

$animationTime: 200ms;

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 370px;
  border-radius: 4px;
  background-color: #fff;

  @media only screen and (min-width: 1000px) {
    border-radius: 0;
  }
}

.imageWrap {
  position: relative;
  display: flex;
  justify-content: center;
  width: 330px;
  margin: 0 auto 18px;
}

.imageCloud {
  margin-right: 40px;
}

.imageLogoWrap,
.imageBankWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($tomeBlack, 0.02);
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.08);
}

.imageLogoWrap {
  position: absolute;
  top: 58px;
  left: 52px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.imageBankWrap {
  position: absolute;
  top: 62px;
  left: 201px;
  width: 72px;
  height: 72px;
  border-radius: 14px;
}

.image {
  position: absolute;
  top: 0;
  left: 3%;
  opacity: 0.1;
}

.requestButton {
  min-width: auto;
  width: 34px;
  height: 34px;

  @media only screen and (max-width: 1000px) {
    width: 38px;
    height: 38px;
    border-radius: 6px;
  }
}

.requestButtonImage {
  transform: scaleX(-1)
}

.imageLineWrap {
  position: absolute;
  overflow: hidden;
  top: 79px;
  left: 141px;
  width: 51px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.numbersText {
  position: relative;
  margin-bottom: -3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

//.imageLineMarquee {
//  position: relative;
//  display: flex;
//  align-items: center;
//  animation: marquee 1s linear infinite;
//}
//
//@keyframes marquee {
//  0% {
//    transform: translateX(-15px) }
//  100% {
//    transform: translateX(16px); }
//}

.imageLine {
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  margin-top: 8px;
  border-radius: 2px;
  background-color: rgba(#000, 0.1);
}

.title {
  position: relative;
  display: block;
  margin: 0 0 9px;
  text-align: center;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  color: $fontBlack;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
}

.text {
  position: relative;
  max-width: 340px;
  margin: 0;
  text-align: center;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: rgba($tomeBlack, 0.56);

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }
}

.capTextLink {
  display: inline-block;
  padding: 0 5px;
  border: none;
  border-radius: 4px;
  background: rgba(#000, 0.02);
  box-shadow: inset 0 0 0 1px rgba(#000, 0.12);
  color: rgba($tomeBlack, 0.6);

  &:focus { outline: none }
}

.bottom {
  position: relative;
  display: block;
  width: 100%;
  padding: 24px 24px 8px;
  margin-top: 9px;
  background-color: #fff;
  z-index: 1;
}

.bottomButton {
  width: 100%;
  font-size: 16px;
  padding: 0 16px;
  margin-bottom: 16px;
}
