.widget {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
  //bottom: 90px;
  z-index: 9999;
}

.container {
  position: relative;
  display: block;
  overflow: hidden;
  min-width: 106px;
  min-height: 40px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  transition: box-shadow 300ms linear;
}

.containerClose {
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}

.openButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  padding: 0;
  border: none;
  background: none;
  text-align: center;
  cursor: pointer;
  opacity: 0;

  &:focus {
    outline: none;
  }
}

.openButtonAnimate {
  animation: showButton 200ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes showButton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.title {
  padding: 0 15px;
  line-height: 40px;
  font-size: 15px;
  color: #333333;

  &:before {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    line-height: 8px;
    margin-right: 8px;
    border-radius: 5px;
    vertical-align: 0.1em;
    background-color: #51d161;
    background-image: linear-gradient(to right bottom,#80dc7a,#23c748);
  }
}

.contentWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0;
  width: 0;
  height: 1000px;
  max-height: 40px;
  pointer-events: none;
  transition: all 300ms ease-in-out;
}

.contentWrapOpen {
  width: 380px;
  max-height: 600px;
  opacity: 1;
  pointer-events: auto;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  opacity: 0;
  z-index: 1;
}

.headerShow {
  animation: showHeader 200ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes showHeader {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.closeButton {
  width: 50px;
  height: 40px;
}

.content {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 300px;
  transform: translateY(-50px);
  text-align: center;
  opacity: 0;
}

.contentShow {
  animation: showContent 200ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
    opacity: 1;
    transform: translateY(-50px);
  }
}

.logoWrap {
  position: relative;
  display: block;
}

.logo {
  position: relative;
  display: block;
  width: 100px;
  border-radius: 50%;
}

.logoIco {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 9px;
  height: 9px;
  border-radius: 100px;
  background-color: #51d161;
  background-image: linear-gradient(to right bottom,#80dc7a,#23c748);
}

.email {
  position: relative;
  display: block;
  margin: 20px 0 0;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.text {
  position: relative;
  display: block;
  max-width: 300px;
  margin: 14px 0 0;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}