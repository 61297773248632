@import "../../Constants/colors";

#document {
  width: 100%;
  margin: 0 auto;

  aside {
    position: relative;
    display: block;
    max-width: 60%;
    margin: 0 0 18px;
    letter-spacing: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgba($tomeBlack, 0.56);

    span {
      display: block;
    }
  }
  h2 {
    position: relative;
    display: block;
    margin: 0 0 14px;
    letter-spacing: -1.1px;
    font-weight: 500;
    font-size: 44px;
    line-height: 56px;
    color: $fontBlack;

    @media only screen and (max-width: 900px) {
      font-size: 34px;
      line-height: 44px;
    }
  }
  h3 {
    position: relative;
    display: block;
    margin: 0 0 6px;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 26px;
    line-height: 48px;
    color: $fontBlack;

    @media only screen and (max-width: 900px) {
      font-size: 24px;
      line-height: 44px;
    }
  }
  h4 {
    position: relative;
    display: block;
    margin: 0 0 40px;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: $fontBlack;
  }
  p {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 0 14px;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 26px;
    color: $fontBlack;

    strong {
      font-weight: 500;
    }
    span {
      display: block;
    }
  }

  @media only screen and (max-width: 900px) {
    aside {
      max-width: 90%;
    }
    aside, h2, h3, h4, p {
      padding: 0 24px;
    }
  }
}

.document_pick {
  position: relative;
  display: block;
  width: 100%;
  margin: 38px 0;
}

.document_buttons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
  padding: 38px 68px;
  background: #EDFBFA;

  @media only screen and (max-width: 900px) {
    overflow: hidden;
    display: block;
    padding: 24px 24px 0;
  }
}

.document_button {
  margin: 0 12px;

  @media only screen and (max-width: 900px) {
    margin: 0 0 24px;
  }
}

.document_button_inline {
  width: auto;
  padding: 0 20px;
  margin: 0 auto;

  @media only screen and (max-width: 900px) {
    margin-bottom: 24px;
  }
}

.document_button_icon {
  margin-right: 10px;
}
