@import '~antd/dist/antd';
@import "./Constants/colors";
@import "./fonts.css";
@import "./custom-antd-styles";
@import "./nprogress";

body {
  overflow: auto;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  line-height: 1.42;
  background-color: #f2f2f1;

  @media only screen and (max-width: 1000px) {
    font-size: 15px;
    line-height: 24px;
  }
}

input, button, select, optgroup, textarea {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root {
  position: relative;
  display: block;
  overflow: hidden;
  min-height: 100%;

  @media only screen and (max-width: 1000px) {
    min-height: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a {
  color: $tomeBlack;
  &:hover {
    color: $tomeBlack;
  }
  @media only screen and (min-width: 1000px) {
    &:hover {
      color: $tomeRed;
    }
  }
}

a:focus {
  text-decoration: none;
}

strong {
  font-weight: 500;
}

.base-layout {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 654px;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1000px) {
    padding: 0;
  }
}

.global-flat-box-shadow {
  box-shadow: 0 0 110px -35px rgba($tomeBlack, 0.05);
}
