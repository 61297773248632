@import "../../Constants/colors";

.inputInvalid {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 1px;
  bottom: 2px;
  right: 1px;
  width: 34px;
  margin-top: 0;
  height: auto;
  border-radius: 8px;
  background-color: #fff;
  z-index: 3;
  animation: none;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 6px;
    height: 6px;
    margin-right: 13px;
    border-radius: 3px;
    background-color: $tomeRed;
  }
}

.textAreaInvalid {
  bottom: auto;
  height: 34px;
}

.suffixWrap {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  line-height: 0;
  transform: translateY(-50%);
}

.inputSuffix {
  position: relative;
  display: inline-block;
  height: 16px;
  padding: 1px 6px 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 8px;
  line-height: 16px !important;
  letter-spacing: 0.4px;
  background: rgba(0,0,0,0.05);
  color: rgba($tomeBlack, 0.56);

  @media only screen and (max-width: 1000px) {
    height: 27px;
    font-size: 10px;
    line-height: 27px !important;
  }
}

.wrap {
  position: relative;
  display: block;
  width: 100%;
}

.row {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.label {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  width: 217px;
  border: none;
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: $fontBlack !important;
  transition: color 0.1s linear;
}

.inputWrap {
  flex: 1;
  display: block;
  position: relative;
}
