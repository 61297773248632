@import "../../Constants/colors";

.form {
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
}

.content {
	padding-bottom: 9px;
}

.header {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 32px 24px;
	margin-bottom: 9px;
	background-color: #fff;
}

.title {
	position: relative;
	display: flex;
	margin: 0 0 14px;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: $fontBlack;
}

.subtitle {
	position: relative;
	display: block;
	margin: 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: rgba($tomeBlack, 0.56);
}

.formBlock {
	position: relative;
	display: block;
	overflow: hidden;
	background-color: #fff;
	margin-bottom: 9px;

	&:last-child {
		margin-bottom: 0;
	}
}

.footer {
	position: relative;
	display: block;
	width: 100%;
	background-color: #fff;
	padding: 24px;
}
