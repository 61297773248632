@import "../../Constants/colors";

%square-icon {
  width: 18px;
  height: 18px;
}

@keyframes rotateBigCircleKeyframes {
  100% {
    transform: rotate(250deg);
  }
}

@keyframes strokeBigCircleKeyframes {
  0% {
    stroke-dasharray: 3, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -15;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -48;
  }
}

@keyframes rotateSmallCircleKeyframes {
  0% {
    transform: rotate(-159deg);
  }
  100% {
    transform: rotate(201deg);
  }
}

$animationButtonTime: 1s;

.button {
  box-sizing: border-box;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  min-width: 38px;
  height: 42px;
  padding: 0;
  border: 0;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  cursor: pointer;
  transition: all .2s;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 1000px) {
    height: 48px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
  }
}

.buttonLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.yellow {
  background-image: linear-gradient(135deg, #ffe877, #ffec78);
  background-color: $tomeYellow;
  color: $fontBlack;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0, 0.03);

  &:hover {
    background-color: rgba($tomeYellow, 0.9);
    color: $fontBlack;
  }
  &:active {
    color: $fontBlack;
    background-image: linear-gradient(-45deg, #fbe05e, #f5de5c);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0, 0);
  }

  @media only screen and (max-width: 1000px) {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.06), 0 2px 1px rgba(0,0,0,0.03);
  }
}

.yellowDisable {
  pointer-events: none;
  cursor: default;
  background-image: none;
  background-color: rgba($tomeBlack, 0.05) !important;
  box-shadow: none;
  color: rgba($fontBlack, 0.45);
}

.yellowLoading {
  pointer-events: none;
  cursor: default;
  background-image: linear-gradient(-45deg, rgba(#ffe562, 0.6) 0, rgba(#ffec78, 0.6));
  background-color: rgba($tomeYellow, 0.6);
  color: rgba($black, 0.55);

  animation-name: loading;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.3, 0.32, 0.57, 1);
}

.white {
  color: $fontBlack;
  background-color: $white;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.16), 0 2px 1px rgba(0,0,0, 0.03);

  &:hover {
    color: $fontBlack;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0,0,0, 0.03);
  }

  &:active {
    color: $fontBlack;
    background-color: rgba($tomeBlack, 0.02);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.16);
  }

  &[click-animating-without-extra-node]:after {
    border-color: rgba($tomeBlack, 0.2);
  }

  @media only screen and (max-width: 1000px) {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.16), 0 2px 1px rgba(0,0,0, 0.03);
  }
}

.whiteDisable {
  pointer-events: none;
  cursor: default;
  background-image: none;
  background-color: rgba($tomeBlack, 0.05) !important;
  box-shadow: none;
  color: rgba($fontBlack, 0.45);

  .iconWrap {
    filter: grayscale(1);
    opacity: 0.3;
  }
}

.whiteLoading {
  pointer-events: none;
  cursor: default;
  background-color: rgba($black, 0.1);
  box-shadow: none;
  color: rgba($black, 0.55);

  .iconWrap {
    filter: grayscale(1);
    opacity: 0.3;
  }

  animation-name: loading;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.3, 0.32, 0.57, 1);
}

.defaultSize {}

.smallSize {
  min-width: 34px;
  height: 37px;
  font-size: 13px;
  line-height: 21px;
}

.largeSize {
  min-width: 44px;
  height: 44px;
  font-size: 16px;
}

.iconWrap {
  @extend %square-icon;
  position: relative;
  display: inline-block;
  transition: all 0.2s;
}

.iconWrapMargin {
  margin-right: 8px;
  margin-left: -26px;
}

.loadingWrap {
  @extend %square-icon;
  position: relative;
}

.loading {
  @extend %square-icon;
  position: absolute;
  top: 0;
  left: 0;
}

.text {
  position: relative;
  display: inline-block;
}

[click-animating-without-extra-node]:after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  border-radius: inherit;
  border: 0 solid $tomeYellow;
  opacity: 0.5;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  display: block;
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

