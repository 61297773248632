@import "../../Constants/colors";

.wrapContent {
  flex-direction: column;
}

.header {
  position: relative;
  display: block;
  overflow: hidden;
  height: 185px;
  width: 100%;
  background-color: #fdfdfd;
}

.headerWave {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.headerPerson {
  position: absolute;
  top: 36px;
  left: -19px;
}

.form {
  position: relative;
  width: 100%;
}

.firstRow {
  padding-top: 28px !important;
}

.lastRow {
  transition: border-bottom-color 200ms linear;
  border-bottom-color: transparent;
}

.content {
  position: relative;
  display: block;
  width: 100%;
  padding: 30px 48px 13px 48px;

  &:first-child {
    padding-top: 40px;
  }
}

.contentBorder {
  border-bottom: 1px solid rgba($tomeBlack, 0.1);
}

.line {
  position: relative;
  display: flex;
  align-items: center;
}

.lineTitle {
  position: relative;
  width: 217px;
  font-size: 14px;
  line-height: 22px;
  color: $fontBlack;
}

.lineInput,
.dateInput {
  flex: 1;
  width: 100%;
}

.lineInputDate {
  max-width: 150px;

  @media only screen and (max-width: 1000px) {
    max-width: none;
  }
}
.lineInputSeries {
  width: 120px;

  @media only screen and (max-width: 1000px) {
    width: auto;
    flex: 1;
  }
}
.lineInputNumber {
  flex: 1;
  margin-left: 16px !important;

  @media only screen and (max-width: 1000px) {
    width: auto;
    flex: 1;
  }
}

.check {
  @media only screen and (max-width: 1000px) {
    flex: 1 !important;
  }
}

.rightSideBirthday {
  justify-content: flex-start;
}

.inputSuffix {
  position: absolute;
  top: 10px;
  right: 9px;
  padding: 1px 6px 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 0.4px;
  background: rgba(0,0,0,0.05);
  color: rgba($tomeBlack, 0.56);

  @media only screen and (max-width: 1000px) {
    height: 27px;
    font-size: 10px;
    line-height: 27px !important;
  }
}

.title {
  position: relative;
  display: block;
  margin: 0 0 19px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $fontBlack;
}

.innButton {
  width: auto;
  padding: 0 20px;
  //font-size: 14px;
}

.innInfoText {
  position: relative;
  display: block;
  margin: 0;
  padding-right: 20px;
  letter-spacing: 0;
  font-size: 15px;
  line-height: 24px;
  color: rgba($tomeBlack, 0.56);
}
