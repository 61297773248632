.wrap {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  perspective: 1000px;
}

.card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(141deg, #F6F6F6 0%, #F2F2F2 42%);
}

.closeWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.closeBase {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1050px;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
}

.close {
  position: absolute;
  top: 40px;
  right: -60px;
  display: block;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;

  &:focus { outline: none }
}

.mobileClose {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  padding: 0;
  border: none;
  background: none;

  &:focus { outline: none }

  &:before {
    content: '';
    position: absolute;
    top: -14px;
    right: -14px;
    width: 88px;
    height: 88px;
    border-radius: 44px;
    box-shadow: inset 0 0 0 1px rgba(18,20,19,0.01), 0 3px 8px 0 rgba(#000,0.04);
    background-color: #fff;
  }
}

.mobileCloseIcon {
  position: relative;
  display: block;
}

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  overflow: auto;
}

.base {
  max-width: 1000px !important;
  padding: 0 !important;
}

.contentWrap {
  min-height: 100vh;
  padding: 56px 160px 87px;
  margin: 0 auto;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
  }

  @media only screen and (max-width: 900px) {
    padding: 43px 0 70px;
  }
}
