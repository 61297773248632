@import "../../Constants/colors";

.wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
  background-color: #f2f2f1;
}

.notificationDot {
  position: fixed;
  top: 45px;
  left: 29px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #E02130;
  box-shadow: 0 0 0 5px rgba(#E02130, 0.2);
  opacity: 0;
  transition: opacity 200ms;
  z-index: 2;
}

.notificationDotShow {
  opacity: 1;
}

.backgroundCircle {
  position: absolute;
  top: -50vw;
  left: -50vw;
  width: 100vw;
  height: 100vw;
  border-radius: 50%;
  background: radial-gradient(#f6f6f5, #f2f2f1);
  z-index: 0;
  pointer-events: none;
}

.box {
  max-width: 612px;
  width: 100%;
  margin: -200px auto 0;

  @media all and (max-height: 800px) {
    margin-top: -60px;
  }

  @media all and (max-height: 600px) {
    margin-top: 0;
  }
}

.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 384px;
  width: 100%;
  padding-top: 14px;
  //height: 390px;

  @media only screen and (max-width: 1000px) {
    height: auto;
    padding: 32px 24px;
  }
}

.image {
  width: 291px;
  min-height: 170px;
  height: 184px;
  margin-left: -38px;
  margin-bottom: 18px;

  @media only screen and (max-width: 1000px) {
    margin-left: -18px;
  }
}

.title {
  margin: 0 auto 9px;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: $fontBlack;

  @media only screen and (max-width: 1000px) {
    margin: 22px 0 15px;
    font-size: 21px;
    line-height: 30px;
  }
}

.titleMobile {
  font-size: 19px;
  margin: 0 0 14px;
}

.text {
  max-width: 310px;
  margin: 0 auto;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 22px;
  color: rgba($tomeBlack, 0.56);

  @media only screen and (max-width: 1000px) {
    max-width: none;
    margin: 0;
    font-size: 15px;
    line-height: 24px;
  }
}

